import React from "react"

import Layout from "../components/Layout"
import RecentPosts from "./RecentPosts"
import Categories from "./Categories"
import Footer from "../components/Layout/Footer"
import DynamicCallToAction from "../components/Layout/Footer/DynamicCallToAction"
import NavWithLogo from "../components/Layout/NavWithLogo"
import SeoWithLanguage from "../components/SeoWithLanguage"
import HeroWithLogo from "../components/HeroWithLogo"
import AlternatePagesMeta from "../components/AlternatePagesMeta"

const BlogOverview = () => {
  return (
    <Layout
      footer={<Footer callToAction={<DynamicCallToAction />} />}
      nav={<NavWithLogo />}
    >
      <SeoWithLanguage title="Blog" />
      <AlternatePagesMeta />
      <HeroWithLogo />
      <RecentPosts />
      <Categories />
    </Layout>
  )
}

export default BlogOverview
