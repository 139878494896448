import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import { PostsToShow, ShowMorePosts } from "./types"
import { PostPreview } from "../../types/Post"
import SecondaryButton from "../../components/SecondaryButton"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "4rem",
      [up("lg")]: {
        marginBottom: "3.5rem",
      },
      [up("xl")]: {
        marginBottom: "4.375rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {
  showMorePosts: ShowMorePosts
  postsForLanguage: PostPreview[]
  postsToShow: PostsToShow
}

const LoadMoreButton = ({
  classes,
  showMorePosts,
  postsForLanguage,
  postsToShow,
}: Props) => {
  if (postsForLanguage.length <= postsToShow) {
    return null
  }

  return (
    <div className={classes.root}>
      <SecondaryButton onClick={showMorePosts}>Show More</SecondaryButton>
    </div>
  )
}

export default withStyles(styles)(LoadMoreButton)
