import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"

import { flexBreakpoint } from "../styles"
import { graphql, useStaticQuery } from "gatsby"
import Category from "../../../types/Category"
import Item from "./Item"
import { QueryWithNodes } from "../../../types/queries"
import { useItemsWithSelectedLanguage } from "../../../utils/language"
import { NodeWithId } from "../../../types/Nodes"

const styles = ({ breakpoints: { up } }: Theme) => {
  return createStyles({
    root: {
      margin: 0,
      padding: 0,
      [up(flexBreakpoint)]: {
        display: "flex",
        alignItems: "center",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

interface CategoryWithWeblogs extends Category {
  weblogs: NodeWithId[]
}

interface AllStrapiTags extends QueryWithNodes<CategoryWithWeblogs> {}

interface Data {
  allStrapiTags: AllStrapiTags
}

const List = ({ classes }: Props) => {
  const {
    allStrapiTags: { nodes: categories },
  }: Data = useStaticQuery(graphql`
    query Tags {
      allStrapiTags {
        nodes {
          ...Tag
          localizations {
            id
            locale
          }
          weblogs {
            id
          }
        }
      }
    }
  `)
  const categoriesForLanguage: CategoryWithWeblogs[] = useItemsWithSelectedLanguage<CategoryWithWeblogs>(
    categories
  )

  const renderItem = (category: CategoryWithWeblogs): JSX.Element => {
    return <Item {...category} key={category.id} />
  }

  const getIfCategoryHasWeblogs = (category: CategoryWithWeblogs): boolean => {
    return category.weblogs.length > 0
  }

  const getCategoriesWithWeblogs = (): CategoryWithWeblogs[] => {
    return categoriesForLanguage.filter(getIfCategoryHasWeblogs)
  }

  const renderCategories = (): JSX.Element[] => {
    const categoriesWithWeblogs: CategoryWithWeblogs[] = getCategoriesWithWeblogs()
    const categoriesToShow: CategoryWithWeblogs[] = categoriesWithWeblogs.slice(
      0,
      4
    )
    return categoriesToShow.map(renderItem)
  }

  const renderedCategories: JSX.Element[] = renderCategories()

  return <ul className={classes.root}>{renderedCategories}</ul>
}

export default withStyles(styles)(List)
