import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core"

import PageContainer from "../../components/PageContainer"
import { flexBreakpoint } from "./styles"
import { useBlogOverviewLanguageText } from "../../utils/language"
import List from "./List"

const styles = ({ breakpoints: { up } }: Theme) => {
  const flexBreakpointUp = up(flexBreakpoint)
  const lg = up("lg")
  const xl = up("xl")
  return createStyles({
    root: {
      marginBottom: "7rem",
      [lg]: {
        marginBottom: "10rem",
      },
      [xl]: {
        marginBottom: "12.5rem",
      },
    },
    content: {
      [flexBreakpointUp]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      },
    },
    title: {
      [flexBreakpointUp]: {
        marginRight: "3rem",
      },
      [xl]: {
        marginRight: "5rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const Categories = ({ classes }: Props) => {
  const {
    readByCategory: { title },
  } = useBlogOverviewLanguageText()
  return (
    <section className={classes.root}>
      <PageContainer>
        <div className={classes.content}>
          <Typography className={classes.title} variant="h2">
            {title}
          </Typography>
          <List />
        </div>
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(Categories)
