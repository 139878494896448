import React from "react"
import { withStyles, WithStyles, createStyles, Theme } from "@material-ui/core"
import { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { AllStrapiPostQuery, PostPreview } from "../../types/Post"
import { usePostsForLanguage } from "../../utils/language"
import Posts from "../../components/Posts"
import PageContainer from "../../components/PageContainer"
import { PostsToShow, ShowMorePosts } from "./types"
import { postsPerPage } from "../../constants/posts"
import LoadMoreButton from "./LoadMoreButton"

const styles = ({ breakpoints: { up } }: Theme) => {
  const xl = up("xl")
  return createStyles({
    root: {
      marginBottom: "5rem",
    },
    posts: {
      marginBottom: "1.5rem",
      [xl]: {
        marginBottom: "2rem",
      },
    },
  })
}

interface Props extends WithStyles<typeof styles> {}

const RecentPosts = ({ classes }: Props) => {
  const [postsToShow, setPostsToShow] = useState<PostsToShow>(postsPerPage)

  const showMorePosts: ShowMorePosts = (): void => {
    setPostsToShow(postsToShow + postsPerPage)
  }

  const {
    allStrapiWeblogs: { nodes: posts },
  }: AllStrapiPostQuery<PostPreview> = useStaticQuery(graphql`
    query PostsList {
      allStrapiWeblogs {
        nodes {
          ...PostPreview
        }
      }
    }
  `)

  const postsForLanguage: PostPreview[] = usePostsForLanguage(posts)

  const getPosts = (): PostPreview[] => {
    return postsForLanguage.slice(0, postsToShow)
  }

  const filteredPosts: PostPreview[] = getPosts()

  return (
    <section className={classes.root}>
      <PageContainer>
        <Posts posts={filteredPosts} classes={{ root: classes.posts }} />
        <LoadMoreButton
          showMorePosts={showMorePosts}
          postsToShow={postsToShow}
          postsForLanguage={postsForLanguage}
        />
      </PageContainer>
    </section>
  )
}

export default withStyles(styles)(RecentPosts)
