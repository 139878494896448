import React from "react"
import {
  withStyles,
  WithStyles,
  createStyles,
  Theme,
  Typography,
} from "@material-ui/core"
import { Link } from "gatsby"

import { flexBreakpoint } from "../styles"
import Category from "../../../types/Category"
import { getCategoryLink } from "../../../utils/links"

const styles = ({ breakpoints: { up } }: Theme) => {
  const flexBreakpointUp = up(flexBreakpoint)
  const lg = up("lg")
  const xl = up("xl")

  return createStyles({
    root: {
      listStyle: "none",
      "& + &": {
        marginTop: "1rem",
        [flexBreakpointUp]: {
          marginTop: 0,
          marginLeft: "1rem",
        },
        [lg]: {
          marginLeft: "3rem",
        },
        [xl]: {
          marginLeft: "5.8125rem",
        },
      },
    },
    link: {
      textDecoration: "none",
    },
  })
}

interface Props extends WithStyles<typeof styles>, Category {}

const Item = ({ classes, slug, name }: Props) => {
  const href: string = getCategoryLink(slug)

  return (
    <li className={classes.root}>
      <Link to={href} className={classes.link}>
        <Typography color="textPrimary">{name}</Typography>
      </Link>
    </li>
  )
}

export default withStyles(styles)(Item)
